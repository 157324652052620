body,
html {
  height: 100%;
  overflow-x: hidden;
  background-color: black;
  color: red;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}